require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// External imports
import "bootstrap";

import { menuButton } from "../components/Navbar";
import { searchButton } from "../components/Search";
import { editionScroll } from "../components/Edition";
import { profileButton } from "../components/Profile";
import { richardButton } from "../components/RichardButton";
import { cutHeadings } from "../components/CutHeadings";
import { showCheckboxes, insertProviders, toggleFilters } from "../pages/directory";
// import { previuosEvents } from "../pages/events";
import { imgPopUp  } from "../pages/article_image_pop_up";
import { stripePay  } from "../pages/stripe";
import { stockSearch  } from "../pages/stock_search";

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  stockSearch();
  stripePay();
  editionScroll()
  menuButton();
  richardButton();
  profileButton();
  searchButton();
  cutHeadings();
  showCheckboxes("type-select", "type-check");
  showCheckboxes("sector-select", "sector-check");
  showCheckboxes("geography-select", "geography-check");
  showCheckboxes("asset-select", "asset-check");
  toggleFilters();
  imgPopUp();
  insertProviders();

  const marquee = document.querySelector('.marquee');
  const textWidth = marquee.scrollWidth;
  const containerWidth = marquee.offsetWidth;
  const isMobile = window.innerWidth <= 990;
  const divisor = isMobile ? 150 : 50; // Faster duration for mobile
  const duration = Math.max((textWidth + containerWidth) / divisor, 10); // Adjust the divisor and set a minimum duration

  marquee.style.animationDuration = `${duration}s`;
  marquee.style.animationDelay = '0s';
  console.log(duration)
  
  const tooltips = document.querySelectorAll("[data-toggle=\"collapse\"]")
  tooltips.forEach((tooltip) => {
    tooltip.tooltip()
  })

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  var adminVideo = document.getElementById('admin-video');
  if (adminVideo) {
    adminVideo.addEventListener('mouseenter', function() {
      adminVideo.setAttribute('controls', 'controls');
    });
    adminVideo.addEventListener('mouseleave', function() {
      adminVideo.removeAttribute('controls');
    });
  }

  var providerVideo = document.getElementById('provider-video');
  if (providerVideo) {
    providerVideo.addEventListener('mouseenter', function() {
      providerVideo.setAttribute('controls', 'controls');
    });
    providerVideo.addEventListener('mouseleave', function() {
      providerVideo.removeAttribute('controls');
    });
  }
});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "controllers"
