import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "loadingBar" ]
        
    initialize() {
      this.hitCounter = 0
      this.switchTracker = true
    }

    connect() {
      console.log("infinite scroll")
    }

    scroll(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)

      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")

        let url = `/providers/list_all?counter=${this.hitCounter}`

        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }

    scrollCompanies(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)

      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")

        let url = `/companies?counter=${this.hitCounter}`

        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }

    scrollGeoCompanies(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)

      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")

        let url = window.location.href + `&counter=${this.hitCounter}&geo_companies=true&code=${event.currentTarget.dataset.code}`
        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }

    scrollSectorCompanies(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)

      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")

        let url = window.location.href + `&counter=${this.hitCounter}&sector_companies=true`
        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }

    addCompany(event) {
      const elementChecked = event.currentTarget.checked
      const counter = document.getElementById("cartCounter")
      let url = ''

      if(elementChecked) {
        url = `/companies/add_to_cart/${event.currentTarget.id}`
      } else {
        url = `/companies/remove_from_cart/${event.currentTarget.id}`
      }
      
      fetch(url, { headers: {'Accept': 'text/plain' }})
      .then(response => response.text())
      .then((data) => {
        if(counter.innerText == "") {
          counter.innerText = 1
        } else {
          if(elementChecked) {
            counter.innerText = parseInt(counter.innerText) + 1
          } else {
            counter.innerText = parseInt(counter.innerText) - 1
          }
        }
      })
    }
}